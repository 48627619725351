import { createApi } from "@/store/clubs/global/actions"

export const SET_CLUBS_CENTER_MAP = 'SET_CLUBS_CENTER_MAP'
export const RESET_CLUBS_CENTER_MAP = 'RESET_CLUBS_CENTER_MAP'
export const SET_CLUBS_BOUNDS = 'SET_CLUBS_BOUNDS'
export const SET_CLUBS_FOR_MAP = 'SET_CLUBS_FOR_MAP'
export const CHANGE_CLUBS_MAP_FETCHING = 'CHANGE_CLUBS_MAP_FETCHING'

export const resetClubsCenterMap = () => (dispatch, getState) => {
  dispatch({ type: RESET_CLUBS_CENTER_MAP })
}

export const setBounds = (bounds) => (dispatch, getState) => {
  let res = null
  /* istanbul ignore next */
  console.log({bounds})
  try {
    res = {
      lat: {
        min: bounds.Ta.g < bounds.Ta.i ? bounds.Ta.g : bounds.Ta.i,
        max: bounds.Ta.g > bounds.Ta.i ? bounds.Ta.g : bounds.Ta.i,
      },
      lng: {
        min: bounds.La.g < bounds.La.i ? bounds.La.g : bounds.La.i,
        max: bounds.La.g > bounds.La.i ? bounds.La.g : bounds.La.i,
      }
    }
  } catch (e) {
    const keys = Object.keys(bounds)
    const latKey = keys[ 0 ]
    const lngKey = keys[ 1 ]
    console.log({keys, latKey, lngKey, bounds})

    res = {
      lat: {
        min: bounds[ latKey ].g < (bounds[ latKey ].i || bounds[ latKey ].h) ? bounds[ latKey ].g : (bounds[ latKey ].i || bounds[ latKey ].h),
        max: bounds[ latKey ].g > (bounds[ latKey ].i || bounds[ latKey ].h) ? bounds[ latKey ].g : (bounds[ latKey ].i || bounds[ latKey ].h),
      },
      lng: {
        min: bounds[ lngKey ].g < (bounds[ lngKey ].i || bounds[ latKey ].h) ? bounds[ lngKey ].g : (bounds[ lngKey ].i || bounds[ latKey ].h),
        max: bounds[ lngKey ].g > (bounds[ lngKey ].i || bounds[ latKey ].h) ? bounds[ lngKey ].g : (bounds[ lngKey ].i || bounds[ latKey ].h),
      }
    }
  }
  if (getState().window.size.windowInnerWidth > 1250) {
    const root = document.getElementById('App')
    /* istanbul ignore next */
    if (root.scrollTop > 290) root.scrollTop = getState().window.size.windowInnerWidth <= getState().window.sizes.mob ? 50 : 200
  }
  dispatch({ type: SET_CLUBS_BOUNDS, bounds: res })
}


export const setCenterMap = ({ lat, lng, id }) => dispatch => {
  dispatch({ type: SET_CLUBS_CENTER_MAP, centerMap: { lat, lng, id } })
}

export const createApiForMap = () => (dispatch, getState) => {
  let api = createApi()(dispatch, getState)
  console.log(api, 'createApiForMap')
  delete api.params.limit
  api.params.start_position = 0
  return api
}

export const setClubsForMap = ({ data }) => (dispatch, getState) => {
  let _setClubs = () => {
    const clubs = {}
    const { search } = getState().clubs.filters
    data.clubs.forEach(item => clubs[ item.id ] = { ...item, lng: item.lon })
    if (typeof window !== 'undefined' && window.location.pathname.indexOf('/club/') === 0 && search.value) return
    else {
      dispatch({ type: SET_CLUBS_FOR_MAP, clubs })
    }
  }
  _setClubs()
}
