import React from 'react'
import Link from "next/link"
import PropTypes from 'prop-types'
import styles from './Btn.module.scss'

const Btn = ({
               title,
               styleBtn = 'blue',
               className,
               onClick,
               disabled,
               link,
               isNativeLink,
               target = isNativeLink ? '_blank' : undefined,
               href,
               icon,
               ref
             }) => {
  const attribute = {
    className: styles[ styleBtn ] + (className ? ' ' + className : '') + (disabled ? ' ' + styles.disabled : '') + ' ' + styles.btn
  }
  console.log(ref)

  if (onClick && !disabled) attribute.onClick = onClick

  return (
    link ?
      <>
        { isNativeLink ?
          <a href={ link } { ...attribute } target={ target }
             ref={ ref }
             rel={ target === '_blank' ? 'noopener noreferrer' : '' }>{ icon ? icon : null } { title }</a> :
          <Link href={ href } as={ link }>
            <a { ...attribute } ref={ ref }>{ icon ? icon : null } { title }</a>
          </Link>
        }
      </> :
      <div { ...attribute } ref={ ref }>
        { icon ? icon : null }
        { title }
      </div>
  )
}

Btn.propTypes = {
  styleBtn: PropTypes.oneOf([
    'blue',
    'white',
    'dark',
    'blueIcon',
    'darkTransparent',
    'darkTransparentIcon',
    'blueTransparentIcon',
    'blueTransparent',
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
}

export default Btn
