import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../api"
import { setClubsSearch } from "@/store/clubs/filters/search/actions"

export const RESET_CLUBS_SLUG = 'RESET_CLUBS_SLUG'
export const SET_CLUBS_SLUG = 'SET_CLUBS_SLUG'

export const CHANGE_CLUBS_FETCHING = 'CHANGE_GET_CLUBS_FETCHING'
export const SET_CLUBS = 'SET_CLUBS'
export const SET_CLUBS_COUNT = 'SET_CLUBS_COUNT'
export const SET_CLUBS_ADDRESS_OBJECT = 'SET_CLUBS_ADDRESS_OBJECT'
export const RESET_CLUBS_ADDRESS_OBJECT = 'RESET_CLUBS_ADDRESS_OBJECT'
export const RESET_CLUBS = 'RESET_CLUBS'

export const resetClubsAddressObjectModel = () => ({ type: RESET_CLUBS_ADDRESS_OBJECT })
export const resetClubs = () => ({ type: RESET_CLUBS })

export const resetClubsSlug = () => (dispatch, getState) => dispatch({ type: RESET_CLUBS_SLUG })

export const setClubsAddressObject = ({ city_slug, subway_slug }) => (dispatch, getState) => {
  const addressObject = JSON.parse(JSON.stringify(getState().clubs.global.addressObject))
  console.log(addressObject, 'setClubsAddressObject', { ...addressObject, city_slug })
  dispatch({ type: SET_CLUBS_ADDRESS_OBJECT, addressObject: { ...addressObject, city_slug, subway_slug } })
}

export const setClubsFetching = ({ status }) => (dispatch, getState) => {
  dispatch({ type: CHANGE_CLUBS_FETCHING, status })
}

export const getClubs = ({
                           setMethod = setClubs,
                           getApi = createApi,
                           fetchConst = CHANGE_CLUBS_FETCHING,
                           pathname
                         } = {}) => async (dispatch, getState) => {
  dispatch({ type: fetchConst, status: true })
  let api = getApi()(dispatch, getState)
  let data = await fetcher(api)
  if (data.clubs.length <= 0 && typeof window === 'undefined') throw Error('getClubs - clubs.length === 0, 404')
  setMethod({ data, pathname })(dispatch, getState)
  dispatch({ type: fetchConst, status: false })
}

export const setClubs = ({
                           data,
                           pathname
                         }) => (dispatch, getState) => {
  let _setClubs = () => {
    const clubs = {}
    data.clubs.forEach(item => clubs[ item.id ] = { ...item, lng: item.lon })
    if (pathname && pathname.indexOf('/club/') === 0) {
      setClubsSearch({ search: { clubs } })(dispatch, getState)
    }
    else {
      dispatch({ type: SET_CLUBS, clubs })
      dispatch({ type: SET_CLUBS_COUNT, clubsCount: data.clubs_count })
      setClubsSearch({ search: { clubs } })(dispatch, getState)
    }
  }
  _setClubs()
  // if (typeof window === 'undefined') _setClubs()
  // else setTimeout(() => _setClubs(), 750)
}

export const createApi = () => (dispatch, getState) => {
  const { services, options, subways, price, nets, geo } = getState().clubs.filters.global.selected
  const { search } = getState().clubs.filters

  const { slug, addressObject } = getState().clubs.global
  const { city_slug, subway_slug } = addressObject
  const { service, option } = slug
  const { currentPage, maxRows } = getState().clubs.filters.global.pagination
  const { min, max } = price
  const { lat, lng } = geo
  // lat: 55.947278, lon: 37.50054

  return createParamsApi({
    method: 'getClubs',
    section: 'club',
    errorMessage: 'getClubs',
    params: typeof window !== 'undefined' && window.location.pathname.indexOf('/club/') === 0 && search.value ? { search: search.value } :
      {
        search: search.value,
        services: Object.keys(services).map(key => +key),
        options: Object.keys(options).map(key => +key),
        subways: Object.keys(subways).map(key => +key),
        min,
        max,
        service,
        option,
        start: 0,
        limit: currentPage * maxRows,
        nets: Object.keys(nets).map(key => +key),
        lat,
        lng,
        radius: lat ? 2.5 : '',
        city_slug,
        subway_slug
      },
    frontEndToBackEnd: {
      search: 'club_name',
      services: 'service_ids',
      service: 'service_slug',
      option: 'option_slug',
      options: 'option_ids',
      subways: 'subway_ids',
      nets: 'net_ids',
      min: 'price_from',
      max: 'price_to',
      limit: 'limit',
      start: 'start_position',
      lat: 'user_lat',
      lng: 'user_lon',
      city_slug: 'city_slug',
      subway_slug: 'subway_slug',
      radius: 'radius',
    }
  })
}

export const setSlug = ({ name, value }) => (dispatch, getState) => {
  dispatch({ type: SET_CLUBS_SLUG, data: { [ name ]: value } })
}
