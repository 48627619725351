import { setClubsFetching } from "@/store/clubs/global/actions"
import { setCurrentClubsPage } from "@/store/clubs/filters/global/actions"
import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../../api"
import { getCookie, setCookie } from "@/utils/cookie"

export const SET_CLUBS_SEARCH_VALUE = 'SET_CLUBS_SEARCH_VALUE'
export const RESET_CLUBS_SEARCH_VALUE = 'RESET_CLUBS_SEARCH_VALUE'

export const resetSearchModel = () => (dispatch, getState) => {
  dispatch({ type: RESET_CLUBS_SEARCH_VALUE })
}

export const searchClubs = (currentValue, dontSearch, withFilters) => (dispatch, getState) => {
  dispatch({
    type: SET_CLUBS_SEARCH_VALUE,
    search: { value: currentValue?.slice()?.trim() || '', }
  })
  setClubsFetching({ status: true })(dispatch, getState)
  if (!dontSearch) {
    setTimeout(() => {
      const { value } = getState().clubs.filters.search
      if (value.slice().trim() === currentValue.slice().trim()) {
        setCurrentClubsPage({ currentPage: 1 })(dispatch, getState)
        if (withFilters) getSearchFilter({ search: currentValue })(dispatch, getState)
      }
    }, 300)
  }
}

export const getSearchFilter = ({ search }) => async (dispatch, getState) => {
  let res = await fetcher(createParamsApi({
    section: 'club',
    errorMessage: 'getSearchFilter',
    method: 'getSearchFilters',
    params: {
      term: search,
      start_position: 0,
      limit: 5,
    }
  }))
  setClubsSearch({ search: { filters: res.filters } })(dispatch, getState)
}

export const setClubsSearch = ({ search }) => (dispatch, getState) => {
  dispatch({ type: SET_CLUBS_SEARCH_VALUE, search })
}

export const getHistorySearch = () => (dispatch, getState) => {
  let history = getCookie('searchClubsHistoryString')
  if (history) history = decodeURIComponent(history).split(',').map(item => item.replace(/\"/g, ''))
  else history = []
  console.log({ history }, 'getHistorySearch')
  setClubsSearch({ search: { history } })(dispatch, getState)
  return history
}

export const setHistorySearch = ({ title }) => (dispatch, getState) => {
  let history = getHistorySearch()(dispatch, getState).slice()
  if (!history.includes(title)) history.unshift(title)
  history.slice(0, 25)
  console.log({ history }, 'setHistorySearch')
  // setCookie({ key: 'searchClubsHistory', value: history })
  setCookie({ key: 'searchClubsHistoryString', value: encodeURIComponent(history) })
  setClubsSearch({ search: { history } })(dispatch, getState)
}
