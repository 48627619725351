import Link from 'next/link'
import PropTypes from 'prop-types'
import styles from './BtnLink.module.scss'

const BtnLink = ({
                   isNativeLink,
                   href,
                   title,
                   color = 'blue',
                   className,
                   as,
                   target = '_self',
                   onClickHandler,
                   disabled
                 }) => {
  let handlers = {}
  if (!!onClickHandler) handlers.onClick = onClickHandler
  const disabledClass = disabled ? (' ' + styles.disabled) : ''
  return (
    !href ?
      <span
        className={ styles[ color ] + (className ? " " + className : '') + ' body2' + disabledClass } { ...handlers }>
      { title }
    </span> :
      !isNativeLink ?
        <Link href={ href } as={ as }>
          <a className={ styles[ color ] + (className ? " " + className : '') + ' body2' } { ...handlers }>
            { title }
          </a>
        </Link> :
        <a rel={ target === '_blank' ? 'noopener noreferrer' : '' } { ...handlers } href={ href } target={ target }
           className={ styles[ color ] + (className ? " " + className : '') + ' body2' }>{ title }</a>
  )
}

BtnLink.propTypes = {
  color: PropTypes.oneOf([ 'blue', 'white', 'dark' ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  href: PropTypes.string,
  as: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  onClickHandler: PropTypes.func,
}

export default BtnLink
