import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../../api"
import {
  CHANGE_CLUBS_MAP_FETCHING,
  createApiForMap,
  resetClubsCenterMap,
  setCenterMap,
  setClubsForMap
} from "@/store/clubs/map/actions"
import { getClubs, resetClubsSlug } from "@/store/clubs/global/actions"
import { setCity } from "@/store/clubs/city/actions"
import { resetSearchModel } from "@/store/clubs/filters/search/actions"

export const SET_CLUBS_ACTIVE_RADIO_ID = 'SET_CLUBS_ACTIVE_RADIO_ID'
export const SET_CLUBS_SETTINGS = 'SET_CLUBS_SETTINGS'
export const CHANGE_SELECTED_SETTINGS = 'CHANGE_SELECTED_SETTINGS'
export const RESET_CLUBS_SELECTED_SETTINGS = 'RESET_CLUBS_SELECTED_SETTINGS'
export const SET_CLUBS_CURRENT_PAGE = 'SET_CLUBS_CURRENT_PAGE'

export const setClubsActiveRadioId = activeRadioId => ({ type: SET_CLUBS_ACTIVE_RADIO_ID, activeRadioId })

export const getSettings = () => async (dispatch, getState) => {
  const api = createApiSettings()
  const data = await fetcher(api)
  setSettings({ data: data.filter || data })(dispatch, getState)
  setCity({ data })(dispatch, getState)
  setSelectedSettings()(dispatch, getState)
}

const createApiSettings = () => {
  return createParamsApi({
    section: 'club',
    method: 'getAllSettings',
    errorMessage: 'getSettings ' + 'getAllSettings'
  })
}

export const setSettings = ({ data }) => (dispatch, getState) => {
  const { subways, services, options, nets, app_settings } = data
  const { filter_max_price, filter_min_price } = app_settings
  const price = {
    max: filter_max_price,
    min: filter_min_price
  }
  const settings = { subways, services, options, nets, price }
  dispatch({ type: SET_CLUBS_SETTINGS, settings })
}

export const setSelectedSettings = () => (dispatch, getState) => {
  const { slug } = getState().clubs.global
  const { service, option } = slug
  let _ = [ { slug: service, title: 'services' }, { slug: option, title: 'options' } ].forEach(item => {
    if (item.slug) {
      const found = getState().clubs.filters.global[ item.title ].find((_item) => item.slug === _item.slug)
      if (found) {
        dispatch({ type: CHANGE_SELECTED_SETTINGS, data: { [ item.title ]: { [ found.id ]: found } } })
      }
    }
  })
  resetClubsSlug()(dispatch, getState)
}

export const changeSelectedSettings = ({
                                         data,
                                         type,
                                         withoutMap,
                                       }) => async (dispatch, getState) => {
  const changeMethod = getChangeSelectSettingsMethodByType({ type })
  changeMethod({ data })(dispatch, getState)
  await setCurrentClubsPage({ currentPage: 1, withoutMap })(dispatch, getState)
}

const getChangeSelectSettingsMethodByType = ({ type = 'default' }) => {
  const methods = {
    removeAll: removeAllFilters,
    price: changeSelectedPrice,
    geo: changeSelectedGeo,
    default: changeSelectedSettingsDefault
  }
  return methods[ type ]
}

const removeAllFilters = () => (dispatch, getState) => {
  resetSearchModel()(dispatch, getState)
  resetClubsSlug()(dispatch, getState)
  resetClubsCenterMap()(dispatch, getState)
  dispatch({ type: RESET_CLUBS_SELECTED_SETTINGS })
}

const changeSelectedPrice = ({ data }) => (dispatch, getState) => {
  const { price } = data
  let resetValue = price.reset ? { min: '', max: '' } : null
  dispatch({ type: CHANGE_SELECTED_SETTINGS, data: { price: resetValue || price } })
}

const changeSelectedGeo = ({ data }) => (dispatch, getState) => {
  const { geo } = data
  dispatch({ type: CHANGE_SELECTED_SETTINGS, data: { geo } })
  const { lat, lng } = geo
  if (lat) {
    setCenterMap({ lat, lng })(dispatch, getState)
  }
}

const changeSelectedSettingsDefault = ({ data }) => (dispatch, getState) => {
  const { name, value } = data
  let currentSelectedValue = getState().clubs.filters.global.selected[ name ]
  const item = currentSelectedValue[ value.id ]
  if (item) delete currentSelectedValue[ value.id ]
  else currentSelectedValue[ value.id ] = value
  dispatch({ type: CHANGE_SELECTED_SETTINGS, data: { [ name ]: currentSelectedValue } })
}

export const setCurrentClubsPage = ({ currentPage, withoutMap }) => async (dispatch, getState) => {
  dispatch({ type: SET_CLUBS_CURRENT_PAGE, currentPage })
  if (withoutMap) return await getClubs()(dispatch, getState)
  else {
    return await Promise.all([
      getClubs()(dispatch, getState),
      getClubs({
        setMethod: setClubsForMap,
        getApi: createApiForMap,
        fetchConst: CHANGE_CLUBS_MAP_FETCHING
      })(dispatch, getState)
    ])
  }
}

